const BREAK_ID = `break-id`;
const DRIVE_FROM_ID = `drive-from-id`;
const DRIVE_TO_ID = `drive-to-id`;
const MAIN_SLOT_ID = `main-slot-id`;
const LUNCH_BREAK_ID = `lunch-break-id`;
const UNAVAILABILITY_ORIGIN = `from_unavailability`;

const AVAILABLE_FOR_DRAG = [DRIVE_TO_ID, DRIVE_FROM_ID];

const TIME_MANAGEMENT_REQUIRED_KEYS = [
    'drive_to_start',
    'drive_to_end',
    'slot_start',
    'slot_end',
    'drive_from_start',
    'drive_from_end',
];

export {
    BREAK_ID,
    DRIVE_FROM_ID,
    DRIVE_TO_ID,
    MAIN_SLOT_ID,
    AVAILABLE_FOR_DRAG,
    LUNCH_BREAK_ID,
    TIME_MANAGEMENT_REQUIRED_KEYS,
    UNAVAILABILITY_ORIGIN,
};
