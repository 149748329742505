<template>
<div class='b-plan-table__wrapper h-pos-rel'>
    <table v-if='isMounted'
           class='b-plan-table'
           :class='{ "b-plan-table--hide": monthDataLoading }'
           cellspacing='0'
           cellpadding='0'>
        <colgroup>
            <col>
            <col v-for='i in 7'
                 :key='i'
                 class='b-plan-table__row_color'>
        </colgroup>
        <thead>
            <tr>
                <td></td>
                <td v-for='date in dates'>
                    <div class='b-plan-table_th'
                         :class='{ "b-plan-table_th--active": compareDates(date) }'>
                        {{ $t(getWeekDayMame(date)).substring(0, 3).toUpperCase() }}.
                        <span>
                            {{ getDay(date) }}
                        </span>
                    </div>
                </td>
            </tr>
        </thead>
        <tbody ref='tbody'>
            <tr v-for='(data, timeIndex) in checkCalendarViewTimeBase'
                :key='timeIndex'>
                <td :key='`${forceUpdateKey}--time-line`'>
                    <div class='b-plan-table__time h-flex-justify-start h-p-0 h-mr-5 h-font-12 h-fw-500'
                         :class='{
                             "b-plan-table__time--active": isTimeActive(data.time),
                             "js-vue-scroll-to": data.time === "CALENDAR.TIME.07.AM"
                         }'>
                        {{ $t(data.time) }}
                    </div>
                </td>
                <td v-for='(date, indexDates) in dates'
                    :key='indexDates'
                    class='h-p-5 h-pos-rel'>
                    <div class='h-flex b-plan-table__time__inner'
                         :style='isBookCalendarType ? getTdStyle(date) : null'>
                        <div v-if='showLine(data, date)'
                             :style='showLineStyles'
                             class='b-plan-table__timeline__wrapper'>
                            <div class='b-plan-table__timeline'></div>
                        </div>
                        <div class='b-plan-table__time-card-wrapper'
                             :class='{ "b-plan-table__component--separator": isEndOfWeek(date, indexDates) }'
                             :style='getTdInnerStyle(`${+date}`)'
                             @mouseover='showTimeAppointmentAdd(data.time, +date)'
                             @mouseleave='hideTimeAppointmentAdd'>
                            <AddAnAppointment
                                v-if='isDirectBookingAccess && showAddAppointmentZone({ localTime: data.time, timestamp: +date }) && !isCardOnHover'
                                :fristHalfTime='$t(data.time)'
                                :choosedDay='date'
                                :secondHalfTime='secondHalfTime($t(data.time))'
                                @openAddAppointmentPopup='openAddAppointmentPopup'>
                            </AddAnAppointment>
                            <component
                                :is='tableCardComponent'
                                v-for='(eventData, index) in getEventsByDate(date, data.time)'
                                :id='`qa-${index}`'
                                :key='index'
                                :isOnSiteMethod='isOnSiteMethod'
                                :style='getTableCardStyles(eventData)'
                                :wrapperHeight='wrapperHeight'
                                class='qa-appointment-time-card'
                                :class='{
                                    "h-pointer": !isConsultCalendarServicePoint,
                                    "b-plan-table__component qa-appointment-set-time": isBookCalendarType,
                                    "b-plan-table__component--morning": eventData.start_from_morning,
                                }'
                                :data='eventData'
                                @click.native='() => onTableCardClick(date, eventData)'
                                @mouseleave.native.stop='isCardOnHover = false'
                                @mouseover.native.stop='isCardOnHover = true'>
                            </component>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <portal to='popupWrapper'>
        <UpdateAppointmentPopup
            v-if='isUpdateAppointmentPopup && !isOnSiteMethod'
            isUpdateNow
            @goToCalendar='goToCalendar'
            @saveBookingProcess='saveBookingProcess'
            @close='closeUpdatePopup'>
        </UpdateAppointmentPopup>
        <template v-if='isBookCalendarType && currentEvent && date'>
            <BookAppointmentPopup
                v-if='isShowBookAppointmentPopup'
                :event='currentEvent'
                :selectedAdvisor='selectedAdvisor'
                :appointmentCategory='appointmentCategory'
                :date='date'
                :canUpdateDisabledFields='!!localClient'
                :client='localClient'
                :email='email'
                :phone_number='phone_number'
                :phoneWithPrefix='phoneWithPrefix'
                :branchAddress='branchAddress'
                :currentRoomId='currentRoomId'
                :branchSiteId='branchSiteId'
                :post_code='post_code'
                :country_iso='country_iso'
                :timeManagementData='timeManagementData'
                :isUpdateNowWithCurrentAdvisor='isUpdateNowWithCurrentAdvisor'
                @close='closeBookPopup'>
            </BookAppointmentPopup>
            <SetBookInfoPopup
                v-if='isShowSetInfoPopup'
                @gotClientData='gotClientData'
                @close='closeInfoPopup'>
            </SetBookInfoPopup>
        </template>
        <DirectAppointmentPopup
            v-if='isOpenAddAppointmentPopup && isMatmutAccount'
            :appointmentStartDate='appointmentStartDate'
            :dates='dates'
            @updateData='updateAppointmentsWithDirectBooking'
            @close='isOpenAddAppointmentPopup = false'>
        </DirectAppointmentPopup>
        <template v-if='!isBookCalendarType'>
            <AddOrEditAppointmentByForm
                v-if='isAppointmentPopup'
                class='b-consult-appts-main__popup'
                :appointmentData='appointmentData'
                :data='currentAppointmentData'
                :loading='appointmentLoad'
                @close='isAppointmentPopup = false'
                @cancel='removeAppointment'>
            </AddOrEditAppointmentByForm>
        </template>
        <AppointmentManagementPopup
            v-if='showManagementPopup && currentAppointmentData && onSiteAppointmentId && currentAppointmentUser'
            :dt='currentAppointmentData.slot'
            :advisor='currentAppointmentUser'
            :appointment-title='appointmentCategory.title'
            :appointment-id='onSiteAppointmentId'
            @close='showManagementPopup = false'
            @submit='submitManagementPopup'>
        </AppointmentManagementPopup>
    </portal>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins, Watch, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import dayjs from 'dayjs';
import { filter, sort } from 'ramda';
import VueScrollTo from 'vue-scrollto';
import DateMixin from '@/mixins/dateMixin';
import { showTime, time } from '@/mocks/tableData';
import { EventDataType, ServicePointShiftListType } from '@/types/Events';
import { BookingEventType, ConsultEventType } from '@/types/Availabilities';
import { currentHoursCalculation, dateToString, equalByDay } from '@/helpers/dates';
import { BookCalendarEventCard } from '@/components/simple/BookCalendarEventCard';
import { AddOrEditAppointmentByForm } from '@/views/consult/add-appointment/AddOrEditAppointmentByForm';
import { UpdateAppointmentPopup } from '@/components/popups/UpdateAppointmentPopup';
import { BookAppointmentPopup } from '@/components/popups/BookAppointmentPopup';
import { SetBookInfoPopup } from '@/components/popups/SetBookInfoPopup';
import { ConsultCalendarEventCard } from '@/components/simple/ConsultCalendarEventCard';
import { AppointmentManagementPopup } from '@/components/popups/AppointmentManagementPopup';
import {
    AppointmentCategoryType,
    AppointmentConsultSearchType,
    AppointmentOnSiteDataType,
    AppointmentSearchType,
    BranchAddressType, DriveInformationUpdatePayload,
} from '@/types/Appointment';
import {
    CalendarViewSettingsResponseType,
    CalendarViewSettingsType,
    CalendarViewSettingsUsertype,
} from '@/types/Calendar';
import { ClientType, UserListType } from '@/types/User';
import { AddAnAppointment } from '@/components/simple/AddAnAppointment';
import { DirectAppointmentPopup } from '@/components/popups/DirectAppointmentPopup';
import AccountMixin from '@/mixins/account';
import { FinalePopup } from '@/components/popups/FinalePopup';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import CalendarMixin from '@/mixins/calendar';

const AppointmentStore = namespace('AppointmentStore');
const CalendarStore = namespace('CalendarStore');

type EventType = BookingEventType | ConsultEventType | ServicePointShiftListType

@Component({
    components: {
        AppointmentManagementPopup,
        BookCalendarEventCard,
        AddOrEditAppointmentByForm,
        UpdateAppointmentPopup,
        BookAppointmentPopup,
        ConsultCalendarEventCard,
        SetBookInfoPopup,
        AddAnAppointment,
        DirectAppointmentPopup,
        FinalePopup,
    },
    refs: [`tbody`],
})
export default class PlanTable extends Mixins(DateMixin, AccountMixin, CalendarMixin) {
    @AppointmentStore.State('appointmentDataToUpdate') appointmentDataToUpdate!: AppointmentSearchType | null;
    @CalendarStore.State('usersList') usersList!: Array<UserListType>;

    @Prop({ type: Array, required: true }) readonly dates!: Array<Date>;
    @Prop({ type: Date, default: null }) readonly calendarDate!: Date;
    @Prop({ type: Object, required: true }) readonly appointmentCategory!: AppointmentCategoryType;
    @Prop({ type: Boolean, required: true }) readonly monthDataLoading!: boolean;
    @Prop({ type: Boolean, required: false }) readonly isUpdateAppointmentPopup!: boolean;
    @Prop({ type: Boolean, required: false }) readonly isUpdateNowWithCurrentAdvisor!: boolean;
    @Prop({ type: Boolean, required: false }) readonly isAdvisorNeedToUpdate!: boolean;
    @Prop({ type: Array, required: true }) readonly events!: Array<EventType>;
    @Prop({ type: Array, required: true }) readonly calendarUsers!: Array<CalendarViewSettingsUsertype>;
    @Prop({ type: Object, required: true }) readonly calendarViewData!: CalendarViewSettingsType | null;
    @Prop({ type: Object, default: null }) readonly client!: ClientType;
    @Prop({ type: Object, default: null }) readonly calendarDetails!: CalendarViewSettingsResponseType | null;

    @Prop({ type: Object, default: null }) readonly branchAddress!: BranchAddressType;
    @Prop({ type: String, default: null }) readonly post_code!: string;
    @Prop({ type: String, default: null }) readonly country_iso!: string;
    @Prop({ type: String, default: null }) readonly branchSiteId!: string;
    @Prop({ type: String, default: null }) readonly onSiteAppointmentId!: string;
    @Prop({ type: String, default: null }) readonly currentRoomId!: string | null;

    currentDate: Date = this.getDayDateAtNight();
    currentEvent: EventType | null = null;
    currentAppointmentData: EventType | null = null;
    appointmentData: AppointmentConsultSearchType | null = null;
    timeData: Array<EventDataType> = time;
    forceUpdateKey = 1;
    isMounted: boolean = false;
    isShowBookAppointmentPopup: boolean = false;
    isAppointmentPopup: boolean = false;
    tableWidthMapping: { [key: string]: number } = {};
    isShowSetInfoPopup: boolean = false;
    currentClient: null | ClientType = null;
    date: Date | null = null;
    showTimeAddAppointment: string | null = null;
    timestampAddAppointment: number | null = null;
    mouseOnEvent: boolean = false;
    isCardOnHover: boolean = false;
    appointmentLoad: boolean = false;
    showManagementPopup: boolean = false;
    isOpenAddAppointmentPopup: boolean = false;
    appointmentChosenDay: string | number = '';
    timeStart: string | null = null;
    updateCalendarKey: number = 1;
    email: string = ``;
    phone_number: string = ``;
    timeManagementData: null | DriveInformationUpdatePayload = null;
    phoneWithPrefix: string = ``;

    $refs!: {
        tbody: HTMLElement
    };

    get currentAppointmentUser(): UserListType | null {
        if (!this.usersList || !this.usersList.length || !this.currentAppointmentData) {
            return null;
        }
        // @ts-ignore-next-line
        return this.usersList.find(item => item.id === this.currentAppointmentData.technician_id) || null;
    }

    get selectedAdvisor(): null | UserListType {
        if (!this.usersList || !this.usersList.length || !this.currentEvent) {
            return null;
        }
        // @ts-ignore-next-line
        return this.usersList.find(item => item.id === this.currentEvent.technician_id) || null;
    }

    get currentHour(): string {
        return this.getCurrentDateAsHoursAMPM(new Date(), false);
    }

    get needOpenManagementPopup(): boolean {
        return this.isMatmutAccount && this.isCalendarWithDriveManagement;
    }

    get currentHourPlusOne() {
        return currentHoursCalculation(1, this.$i18n.locale);
    }

    get showLineStyles() {
        return {
            width: `100%`,
            top: `${new Date().getMinutes() / 60 * 100}%`,
        };
    }

    get tableCardComponent(): string {
        return this.isBookCalendarType ? `BookCalendarEventCard` : `ConsultCalendarEventCard`;
    }

    get localClient(): null | ClientType {
        return this.currentClient || this.client;
    }

    get appointmentStartDate(): Date | null {
        if (!this.appointmentChosenDay) return null;
        const startDay = new Date(this.appointmentChosenDay);
        const timeData = this.getHoursAndMinutesFromAMPM((this.timeStart as string));
        return new Date(startDay.setHours(timeData.hours, timeData.minutes, 0, 0));
    }

    get wrapperHeight(): null | number {
        return this.$refs.tbody && this.forceUpdateKey && this.events ? this.$refs.tbody.offsetHeight : null;
    }

    get isOnSiteMethod() {
        return this.$route.params.type === 'on-site';
    }

    get isServicePoint() {
        return this.$route.params.type === 'service-point';
    }

    get isDirectBookingAccess() {
        if (this.$route.params && this.$route.params.id) {
            return this.isMatmutAccount && this.user().id === this.$route.params.id;
        }

        return false;
    }

    get checkCalendarViewTimeBase() {
        // BookCalendars have view time such as specified in the settings
        // ConsultCalendars stretch for min and max event time
        const dates: Array<string> = this.dates.map(item => this.getPrettyStringDate(item));
        // @ts-ignore
        const filteredEventOnWeek = this.events.filter(item => dates.includes(this.getPrettyStringDate(new Date(item.slot))));
        if (this.isBookCalendarType && this.calendarViewData) {
            const hoursStart = parseInt(this.calendarViewData.monday_time_start, 10);
            const hoursEnd = parseInt(this.calendarViewData.monday_time_end, 10);
            return time.slice(
                time.findIndex(item => item.hours === hoursStart), time.findIndex(item => item.hours === hoursEnd)
            ).filter(item => item.main);
        }
        if (!filteredEventOnWeek.length) {
            return showTime;
        }
        const eventWithMaxDate = filteredEventOnWeek.reduce((prevEvent: EventType, currentEvent: EventType) => {
            const prevDate = new Date(this.eventDate(prevEvent));
            const currentDate = new Date(this.eventDate(currentEvent));
            const isPrevMore = (prevDate.getHours() > currentDate.getHours()) ||
                (prevDate.getHours() === currentDate.getHours() && prevDate.getMinutes() > currentDate.getMinutes());
            return isPrevMore ? prevEvent : currentEvent;
        });
        // @ts-ignore-next-line
        const eventWithMinDate = filteredEventOnWeek.reduce((prevEvent: BookingEventType, currentEvent: BookingEventType) => {
            const prevDate = new Date(this.eventDate(prevEvent));
            const currentDate = new Date(this.eventDate(currentEvent));
            const isPrevSmaller = (prevDate.getHours() < currentDate.getHours()) ||
                (prevDate.getHours() === currentDate.getHours() && prevDate.getMinutes() < currentDate.getMinutes());
            return isPrevSmaller ? prevEvent : currentEvent;
        });
        const minHour = new Date(this.eventDate(eventWithMinDate)).getHours();
        const minMinutes = new Date(this.eventDate(eventWithMinDate)).getMinutes();
        const maxHour = new Date(this.eventDate(eventWithMaxDate)).getHours();
        const maxMinutes = new Date(this.eventDate(eventWithMaxDate)).getMinutes();
        const startIndexOfEvent = time.findIndex(item => item.hours === minHour && item.minutes === minMinutes);
        const endIndexOfEvent = time.findIndex(item => item.hours === maxHour && item.minutes === maxMinutes);
        const startIndexBase = time.findIndex(item => item.hours === showTime[0].hours && item.minutes === showTime[0].minutes);
        const endIndexBase = time.findIndex(item => item.hours === showTime[showTime.length - 1].hours && item.minutes === showTime[showTime.length - 1].minutes);
        const startIndexToSlice = startIndexOfEvent < startIndexBase ? startIndexOfEvent : startIndexBase;
        const endIndexToSlice = endIndexOfEvent > endIndexBase ? endIndexOfEvent : endIndexBase;
        const available = this.timeData.slice(startIndexToSlice - 3, endIndexToSlice + 3);
        const availableTimes = available.filter(localTime => localTime.main);
        return availableTimes.length ? availableTimes : showTime;
    }

    get highestDay() {
        const daysOfWeek = this.dates.map(date => date.getDay());
        if (daysOfWeek.includes(0)) {
            return 0; // Set Sunday as the highest day if it exists
        }

        return Math.max(...daysOfWeek);
    }

    isEndOfWeek(date: Date, indexDates: number) {
        return date.getDay() === this.highestDay && indexDates !== this.dates.length - 1;
    }

    goToCalendar() {
        this.$emit('closeUpdatePopup', false);
        this.date = null;
        this.currentEvent = null;
        this.$emit('advisorNeedToUpdateState', true);
    }

    saveBookingProcess() {
        if (this.appointmentDataToUpdate) {
            this.date = new Date(this.appointmentDataToUpdate.dt_start as string);
            (this.currentEvent as any) = {
                slot: dateToString(new Date(this.appointmentDataToUpdate.dt_start as string), false, true),
                technician_id: this.appointmentDataToUpdate.advisor_technician_id,
            };
        }

        this.$emit('updateNowState', true);
        this.isShowBookAppointmentPopup = true;
        this.$emit('closeUpdatePopup', false);
    }

    filterEventsByDate(date: Date) {
        // @ts-ignore
        return this.events.filter(item => new Date(this.eventDate(item)).getDate() === date.getDate());
    }

    getEventOrder(event: EventType): number {
        return new Date(this.eventDate(event)).getMinutes() / 15 + 1;
    }

    getTdStyle(date: Date) {
        const eventsByDate = this.filterEventsByDate(date);
        let maxEventInLineCount: number = 0;
        for (let i = 0; i < eventsByDate.length; i++) {
            const event = eventsByDate[i];
            // @ts-ignore-next-line
            const count = this.getEventOrder(event);
            if (maxEventInLineCount < count) {
                maxEventInLineCount = count;
            }
            if (maxEventInLineCount === 4) break;
        }
        const width: number = maxEventInLineCount ? this.cellSize * maxEventInLineCount : 160;
        return {
            width: `${width}px`,
        };
    }

    getTableCardStyles(eventData: EventType) {
        const userStyles: { borderLeft?: string } = {};
        if (!this.isBookCalendarType) {
            const field = this.isConsultCalendarServicePointShifts ? 'user_id' : 'assigned_to_id';
            // @ts-ignore-next-line
            const user = this.calendarUsers.find(item => item.id === (eventData as ConsultEventType)[field]);
            const color = user && user.color ? user.color.split(' - ')[0] : `transparent`;
            userStyles.borderLeft = `4px solid ${color}`;

            if (this.isConsultCalendarServicePoint) {
                const hourHeight = 54;
                // @ts-ignore-next-line
                const minutes = dayjs(eventData.dt_end).diff(eventData.dt_start, 'minute');
                // @ts-ignore-next-line
                userStyles.height = `${(minutes < 20 ? 20 : minutes) * hourHeight / 60}px`;
            }
        }
        if (this.isBookCalendarType) {
            return this.getCardStyle(eventData);
        }
        return userStyles;
    }

    getEventsByDate(date: Date, timeTranslation: string | null): Array<EventType> {
        const localTime = this.$i18n.t(timeTranslation || ``);
        const eventsByDate = filter((instance: EventType) => equalByDay(new Date(this.eventDate(instance)), date), this.events);
        if (!eventsByDate.length) {
            return [];
        }
        const sortedEvents = sort((instanceA: EventType, instanceB : EventType) => {
            return +new Date(this.eventDate(instanceA)) - +new Date(this.eventDate(instanceB));
        }, eventsByDate);
        return sortedEvents.filter(localEvent => {
            const localDate = this.eventDate(localEvent);
            const hour = this.isConsultCalendarServicePointShifts ? dayjs(localDate).hour() : new Date(localDate).getUTCHours();
            return hour === this.getHoursAndMinutesFromAMPM(localTime).hours;
        });
    }

    getCardStyle(event: EventType) {
        const order: number = this.getEventOrder(event);
        return {
            left: `${(order - 1) * this.cellSize + 10}px`,
        };
    }

    closeBookPopup(isError: boolean = false) {
        this.isShowBookAppointmentPopup = false;
        this.currentEvent = null;
        this.timeManagementData = null;
        if (!this.appointmentDataToUpdate) {
            this.currentClient = null;
        }

        if (this.isUpdateNowWithCurrentAdvisor && isError) {
            this.$emit('closeUpdatePopup', true);
        }
    }

    closeInfoPopup() {
        this.isShowSetInfoPopup = false;
        this.timeManagementData = null;
        this.currentEvent = null;
    }

    getTdInnerStyle(key: string) {
        return {
            minWidth: `${(this.tableWidthMapping[key] || 1) * 95}px`,
        };
    }

    closeUpdatePopup() {
        this.$emit('closeUpdatePopup', false);
    }

    onTableCardClick(date: Date, data: EventType) {
        if (this.isConsultCalendarServicePoint || this.isAlmAccount) {
            return;
        }
        this.openBookPopup(date, data);
    }

    async openBookPopup(date: Date, data: EventType, timeManagementData?: null | DriveInformationUpdatePayload) {
        this.date = date;
        this.currentAppointmentData = data;
        if (this.needOpenManagementPopup && !this.timeManagementData) {
            this.showManagementPopup = true;
            this.currentEvent = data;
        } else if (this.isBookCalendarType) {
            this.currentEvent = {
                ...data,
                ...(timeManagementData && timeManagementData.slot_start && { slot: timeManagementData.slot_start }),
            };
            if (this.client || this.localClient) {
                this.isShowBookAppointmentPopup = true;
            } else {
                this.isShowSetInfoPopup = true;
            }
        } else {
            this.isAppointmentPopup = true;
            this.appointmentLoad = true;
            const { appointment } = await AppointmentWebApi.getAppointment(data.id);
            this.appointmentData = appointment;
            this.appointmentLoad = false;
        }
    }

    removeAppointment() {
        this.isAppointmentPopup = false;
        this.$emit(`updateEvents`);
    }

    gotClientData(payload: { client: null | ClientType, email: string, phone_number: string, phoneWithPrefix: string }) {
        if (payload.client) {
            this.currentClient = payload.client;
        } else {
            this.phone_number = payload.phone_number;
            this.email = payload.email;
        }

        if (payload.phoneWithPrefix) {
            this.phoneWithPrefix = payload.phoneWithPrefix;
        }
        this.isShowSetInfoPopup = false;
        this.isShowBookAppointmentPopup = true;
    }

    isTimeActive(currentTime: string) {
        return this.currentHour === this.$i18n.t(currentTime) || this.currentHourPlusOne === this.$i18n.t(currentTime);
    }

    compareDates(date: Date) {
        return date.getTime() === this.currentDate.getTime();
    }

    eventDate(instance: EventType): string {
        if (this.isBookCalendarType) {
            return (instance as BookingEventType).slot;
        }
        return (instance as ConsultEventType).dt_start;
    }

    showLine(eventDataType: EventDataType, date: Date) {
        return (
            this.forceUpdateKey &&
            this.getCurrentDateAsHoursAMPM(new Date()) === this.$i18n.t(eventDataType.time) &&
            +this.currentDate === +date
        );
    }

    showAddAppointmentZone({ localTime, timestamp }: { localTime: string, timestamp: number }) {
        const hoursData = this.getHoursAndMinutesFromAMPM(this.$i18n.tc(localTime));
        const calendarDate = new Date(timestamp);
        calendarDate.setHours(hoursData.hours, hoursData.minutes, 0, 0);
        return (
            this.isDirectBookingAccess &&
            !this.mouseOnEvent &&
            this.showTimeAddAppointment === localTime &&
            this.timestampAddAppointment === timestamp &&
            +new Date() < +calendarDate
        );
    }

    secondHalfTime(localTime: string) {
        const hoursData = this.getHoursAndMinutesFromAMPM(localTime);
        const date = new Date();
        date.setHours(hoursData.hours, 30, 0, 0);
        return this.formatAMPM(date, false);
    }

    showTimeAppointmentAdd(timeAddAppointment: string, timestamp: number) {
        this.showTimeAddAppointment = timeAddAppointment;
        this.timestampAddAppointment = timestamp;
    }

    hideTimeAppointmentAdd() {
        this.showTimeAddAppointment = null;
        this.timestampAddAppointment = null;
    }

    openAddAppointmentPopup(date: string, chosenTime: string) {
        this.timeStart = chosenTime;
        this.appointmentChosenDay = date;
        this.isOpenAddAppointmentPopup = true;
    }

    updateAppointmentsWithDirectBooking(data: AppointmentOnSiteDataType) {
        this.addEventToStoreHandler({
            dt_end: data.dt_end as string,
            dt_start: data.dt_start as string,
            duration: data.duration,
            id: data.id,
            title: data.title,
            booking_platform: 'saas',
            booked_in: 'direct',
        });
    }

    async created() {
        setInterval(() => {
            this.forceUpdateKey += 1;
        }, 1000 * 60);

        if (this.appointmentDataToUpdate) {
            if (this.appointmentDataToUpdate.client_id) {
                const clientDetailsArray = this.appointmentDataToUpdate.client_phone ?
                    await AppointmentWebApi.getClientDetailsByPhoneFilterByID(this.appointmentDataToUpdate.client_phone) :
                    await AppointmentWebApi.getClientDetailsByEmailFilterByID(this.appointmentDataToUpdate.client_email);
                if (clientDetailsArray.length) {
                    // @ts-ignore
                    this.currentClient = clientDetailsArray.find((client: ClientType) => client.id === this.appointmentDataToUpdate.client_id);
                    this.phone_number = this.appointmentDataToUpdate.client_phone;
                }
            }
        }
    }

    submitManagementPopup(payload: DriveInformationUpdatePayload): void {
        this.timeManagementData = payload;
        this.showManagementPopup = false;
        if (this.date && this.currentEvent) {
            this.openBookPopup(this.date, this.currentEvent, this.timeManagementData);
        }
    }

    async mounted() {
        this.isMounted = true;
        await this.$nextTick();
        const options = {
            container: '.fw-horizontal-scroll__content',
            lazy: false,
            offset: -40,
        };
        VueScrollTo.scrollTo(document.querySelector(`.js-vue-scroll-to`)!, 300, options);
    }

    @Emit('addEventToStore')
    addEventToStoreHandler(event: ConsultEventType): ConsultEventType {
        return event;
    }

    @Watch('events')
    eventsHandler() {
        this.updateCalendarKey += this.updateCalendarKey;
    }
}
</script>

<style lang='sass'>
$left-td-width: 65px

.b-global-loading
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    background: #fff
    top: 0
    left: 0
    z-index: 10

.b-plan-table
    padding: 0 60px 0 0
    min-width: 100%
    transition: opacity .2s
    opacity: 1

    thead tr:first-child > td
        position: sticky
        top: 0
        z-index: 30

    .b-worker-time-card__inner
        overflow: hidden
        position: relative

    .fw-time-card__time
        padding: 1px 0
        font-size: 11px

    &--hide
        opacity: 0

    &__wrapper
        width: calc(100vw - 350px)

    &_th
        height: 34px
        border-radius: 5px
        background-color: #E5F5F7
        color: #213F6B
        display: flex
        align-items: center
        justify-content: center
        position: relative
        margin-right: 8px
        font-size: 14px
        font-weight: bold
        letter-spacing: 0.3px
        line-height: 14px
        border: 1px solid transparent

        &--active
            background-color: rgba(39,219,189,0.1)
            color: $main-green
            border: 1px solid #27DBBD
            b
                color: $main-green
                &:before
                    content: ''
                    color: $main-green
                    border-radius: 10px
                    position: absolute
                    z-index: 1
                    margin-top: -1px

        b
            display: flex
            align-items: center
            justify-content: center
            margin-left: 10px
            span
                position: relative
                z-index: 2

    td:first-of-type
        margin: 0 12px 0 5px
        text-align: center
        width: $left-td-width
        min-width: $left-td-width
        padding-right: 0
        vertical-align: top
        position: sticky
        left: 0
        z-index: 10
        border-right: none

        div
            position: relative
            left: 0px
            height: 54px
            background-color: #fff
            font-size: 11px

    td:not(:first-of-type)
        min-width: 160px

    thead
        td
            padding: 5px 0
            background: #fff

    tbody
        td
            border-right: 8px solid #fff

    &__row_color
        background: #F8F9FA

    &__row_color--alt
        background: repeating-linear-gradient(-78deg, #D5DDE8, #D5DDE8 .5px, #fff .4px, #fff 5px)

    &__time
        color: #BEC7D3
        font-weight: normal

        &--active
            color: $dark-blue
            font-weight: bold

    &__time-card-wrapper
        width: 100%
        display: flex
        min-height: 44px
        height: 44px

@include media('<=tablet')
    .b-plan-table
        &__wrapper
            width: 100%

.b-plan-table__timeline
    width: 100%
    height: 1px
    background-color: $dark-blue
    position: relative

    &__wrapper
        width: 100%
        position: absolute
        z-index: 5
        left: 0

.b-plan-table__component
    position: absolute !important

.b-plan-table__component--separator:after
    background: rgba(128, 128, 128, 0.07)
    content: ''
    position: absolute
    top: 0
    right: 0
    width: 3px
    height: 100%
</style>
