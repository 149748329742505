<template>
<div class='b-calendar-page__wrapper h-width-100p'
     :class='{ "b-calendar-page__wrapper--is-open": showCalendar }'>
    <div
        v-if='showCalendar'
        ref='leftBar'
        class='b-appointment-left-bar'
        :class='{ "b-over-fixed-background": isFixedBg }'>
        <div v-show='!(showForm && isFixedBg)'>
            <PlanAppointmentInfo
                v-if='isBookCalendarType'>
                <div>
                    <ConsultCard
                        v-for='information in bookingInformation'
                        :key='information.title'
                        :information='information'>
                    </ConsultCard>
                </div>
            </PlanAppointmentInfo>
            <PlanAppointmentInfo v-if='isConsultCalendarType'></PlanAppointmentInfo>
            <CalendarDatePicker
                class='h-mt-20'
                :dateCurrent='date'
                :isBookCalendarType='isBookCalendarType'
                :reservedSlotsData='calendarReservedSlotsData'
                :dateUntil='dateUntil'
                @changeCalendarMonth='changeCalendarMonth'
                @updateDates='updateDates'>
            </CalendarDatePicker>
            <div v-if='isBookCalendarType || isMobile()'
                 class='h-flex-center h-mt-20'>
                <FwButton
                    size='small'
                    borderRadiusType='small-border'
                    color='blue'
                    @click='$router.go(-1)'>
                    {{ $t('BUTTON.BACK') }}
                </FwButton>
            </div>
            <VerticalUserList
                v-if='calendarUsers && calendarUsers.length'
                :users='calendarUsers'
                @toggleUserActive='toggleUserActive'>
            </VerticalUserList>
        </div>
        <div>
            <div
                class='b-calendar-page__info-link h-pointer'
                @click='goBack'>
                <CalendarInfo
                    v-if='isConsultCalendarType && information'
                    :informationData='information'>
                </CalendarInfo>
            </div>
        </div>
        <div v-if='slotsLoading'
             class='b-fw-spin-loader-wrapper'>
            <FwSpinLoader
                :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                class='h-flex-center loader'
                :isActive='slotsLoading'
                className='h-p-20'>
            </FwSpinLoader>
        </div>
    </div>
    <div class='b-appointment-main b-calendar-page__height-wrapper h-pb-50'>
        <div v-if='loading'
             class='b-fw-spin-loader-wrapper'>
            <FwSpinLoader
                :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                class='h-flex-center loader'
                :isActive='loading'
                className='h-p-20'>
            </FwSpinLoader>
        </div>
        <div>
            <div v-if='isBookCalendarType && !loading'
                 :style='{ opacity: startLoading ? 0 : 1 }'
                 class='b-user-list__main--scroll'>
                <FwHorizontalScroll
                    :isPageLoaded='true'
                    slotWrapperClass='h-flex h-flex-dir-column h-height-auto-force h-width-100p'
                    iconClass='h-opacity-1'
                    newClass='fw-arrow-big-circle'
                    newClassModifier='fw-arrow-big-circle--middle-size'
                    :arrowBgWidth='null'
                    colorIcon='#fff'
                    btnLocation='between'>
                    <template #content>
                        <div class='b-user-list__main--scroll-list h-align-items-start'>
                            <UserList
                                v-if='!pageLoading && showTable && !showRoomList'
                                :users='usersList'
                                :userFilterId='userFilterId'
                                @click='setActiveUser'>
                            </UserList>

                            <RoomList
                                v-if='showRoomList'
                                :rooms='branchVideoRooms'
                                :currentRoomId='branchVideoRoomId || startBranchVideoRoomId'
                                @click='updateRoomId'>
                            </RoomList>
                        </div>
                    </template>
                </FwHorizontalScroll>
            </div>
            <div v-else-if='!isBookCalendarType'
                 class='b-calendar-page__header-nav__wrapper'>
                <CalendarHeaderNav
                    :calendarDate='calendarDate'
                    :hideBackButton='false'
                    :hideForwardButton='false'
                    @changeDay='changeDay'>
                    <template #main-button>
                        <FwButton
                            class='b-calendar-header-nav__button h-uppercase'
                            color='choose'
                            size='little'
                            borderRadiusType='small-border'
                            fontSize='12px'
                            @click.native='goToday'>
                            {{ $t('CALENDAR.BUTTON.TODAY') }}
                        </FwButton>
                    </template>

                    <template #current-time>
                        {{ currentTime | capitalizeAll }}
                    </template>

                    <template
                        v-if='isConsultCalendarServicePoint'
                        #extra-title>
                        {{ isConsultCalendarServicePointShifts ? $t('SERVICES_POINT.SHIFT.TXT') : $t('SELECT.APPOINTMENT.TITLE') }}
                    </template>

                    <template #right-block>
                        <FwButton
                            v-if='isConsultCalendarServicePoint'
                            class='b-calendar-header-nav__button b-calendar-header-nav__button--small h-mr-10'
                            color='choose'
                            size='little'
                            borderRadiusType='small-border'
                            fontSize='12px'
                            @click.native='toggleServicePointView'>
                            <FwIcon
                                class='h-mr-5 h-mt-2'
                                icon='reccurance-icon'
                                size='16'
                                color='#213F6B'>
                            </FwIcon>
                            <span class='h-mt-1'>
                                {{ isConsultCalendarServicePointShifts ? $t('CALENDAR.APPOINTMENT.LABEL') : $t('SETTINGS.ROLE.RIGHTS.SHIFTS') }}
                            </span>
                        </FwButton>
                    </template>
                </CalendarHeaderNav>
            </div>
            <FwHorizontalScroll
                v-if='!loading'
                isPageLoaded
                slotWrapperClass='h-flex h-flex-dir-column h-pb-30'
                wrapperClass='b-calendar-page__corner-fix'
                newClass='fw-horizontal-scroll__shadow'
                colorIcon='#203F6A'
                btnLocation='between'
                sizeIcon='20'
                slotScrollClass='h-p-0 h-mb-30'
                iconClass='h-opacity-0_8'>
                <template #content>
                    <div class='b-appointment-main__body'>
                        <PlanTable
                            v-if='showTable && dates.length && (calendarViewData || isConsultCalendarType)'
                            :key='planTableKey'
                            :dates='calculateDatesByCalendarSettings'
                            :events='appointments'
                            :type='$route.name'
                            :client='client'
                            :cellSize='cellSize'
                            :calendarViewData='calendarViewData'
                            :appointmentCategory='appointmentCategory'
                            :isBookCalendarType='isBookCalendarType'
                            :isUpdateAppointmentPopup='isUpdateAppointmentPopup'
                            :isUpdateNowWithCurrentAdvisor='isUpdateNowWithCurrentAdvisor'
                            :isAdvisorNeedToUpdate='isAdvisorNeedToUpdate'
                            :currentRoomId='currentRoomId'
                            :branchSiteId='branchSiteId'
                            :monthDataLoading='monthDataLoading'
                            :branchAddress='branchAddress'
                            :post_code='post_code'
                            :country_iso='country_iso'
                            :calendarDetails='information'
                            :calendarUsers='calendarUsers'
                            :onSiteAppointmentId='appointmentOnSiteData ? appointmentOnSiteData.id : null'
                            @addEventToStore='setEventToStore'
                            @closeUpdatePopup='closeUpdatePopup'
                            @updateNowState='updateNowState'
                            @advisorNeedToUpdateState='advisorNeedToUpdateState'
                            @updateEvents='updateEvents'
                            @openForm='openForm'>
                        </PlanTable>
                    </div>
                </template>
            </FwHorizontalScroll>
            <div v-if='!isDesktop()'
                 class='b-appointment-main__show-button'
                 @click='showCalendar = !showCalendar'>
                <FwIcon
                    icon='arrow-right'
                    size='24px'
                    color='#fff'>
                </FwIcon>
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import dayjs from 'dayjs';
import Vue from 'vue';
import { Route } from 'vue-router';
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { clone } from 'ramda';
import { CalendarHeaderNav } from '@/components/calendar/CalendarHeaderNav';
import DateMixin from '@/mixins/dateMixin';
import { CalendarViewSettingsResponseType, CalendarViewSettingsUsertype, GetMonthDataPayload, CalendarViewSettingsType } from '@/types/Calendar';
import { CompanyData } from '@/types/Companies';
import { PlanTable } from '@/components/common/PlanTable';
import { PlanAppointmentInfo } from '@/components/common/PlanAppointmentInfo';
import { PlanAppointmentForm } from '@/components/common/PlanAppointmentForm';
import { CalendarDatePicker } from '@/components/nodes/CalendarDatePicker';
import { CompanyCalendarInfo } from '@/components/nodes/CompanyCalendarInfo';
import { WorkersCalendarInfo } from '@/components/nodes/WorkersCalendarInfo';
import { CalendarInfo } from '@/components/calendar/CalendarInfo';
import { CompanyPopup } from '@/components/popups/CompanyPopup';
import AuthMixin from '@/mixins/auth';
import { dateToString, getDateWithDelayInDays } from '@/helpers/dates';
import { EventsType, CompanyEventType, WorkersEventType, ShiftSlotsResponseType } from '@/types/Events';
import { BaseMetaType } from '@/types/Response';
import { UserListType, ClientType } from '@/types/User';
import { companyDataProps } from '@/helpers/company';
import CalendarMixin from '@/mixins/calendar';
import NoDataMixin from '@/mixins/no-data-mixin';
import AccountMixin from '@/mixins/account';
import PermissionsMixin from '@/mixins/permissions';
import TranslateMixin from '@/mixins/TranslateMixin';
import { CONSULT_MONTH_VIEW_CALENDARS } from '@/helpers/calendar';
import { SHIFTS_CALENDAR_PARAM } from '@/helpers/consts';
import {
    AppointmentCategoryType,
    BranchAddressType,
    AvailabilitiesByPostCodePayloadType,
    AvailabilitiesBranchFaceToFacePayloadType,
    AvailabilitiesVideoconferencePayloadType,
    AvailabilitiesOnSitePayloadType,
    AvailabilitiesOnSiteInBranchPayloadType,
    AppointmentOnSiteDataType,
    BranchAddressVideoRoomType,
    AppointmentSearchType,
    CategoryLocationSkillNameType,
} from '@/types/Appointment';
import { UserList } from '@/components/common/UserList';
import { VerticalUserList } from '@/components/common/VerticalUserList';
import { RoomList } from '@/components/common/RoomList';
import { CalendarApi } from '@/api/calendar/CalendarApi';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { BookingEventType, ConsultEventType, SetAvailabilitiesToStoreType } from '@/types/Availabilities';
import { parseAddress } from '@/helpers/address';
import { ParsedAddressData } from '@/types/GoogleMap';
import { SettingsWebApi } from '@/api/settings/SettingsApi';
import { FLOWS_WITH_ROOM_ID_REQUIRE } from '@/helpers/appointment';
import { informationDataType } from '@/types/Consult';
import { BranchSitesDataType } from '@/types/Site';
import { capitalizeAll } from '@/helpers/string';
import { ConsultCard } from '@/components/simple/ConsultCard';
import { EventsWebApi } from '@/api/events/EventsApi';

const AppointmentStore = namespace('AppointmentStore');
const CommonStore = namespace('CommonStore');
const CalendarStore = namespace('CalendarStore');
const GlobalStore = namespace('GlobalStore');

@Component({
    components: {
        CalendarInfo,
        ConsultCard,
        CalendarHeaderNav,
        CalendarDatePicker,
        CompanyCalendarInfo,
        WorkersCalendarInfo,
        PlanTable,
        PlanAppointmentInfo,
        PlanAppointmentForm,
        CompanyPopup,
        UserList,
        VerticalUserList,
        RoomList,
    },
    refs: [`leftBar`],
})
export default class CalendarPage extends Mixins(DateMixin, AuthMixin, CalendarMixin, NoDataMixin, AccountMixin, PermissionsMixin, TranslateMixin) {
    @CommonStore.Getter('isFixedBg') isFixedBg!: boolean;

    @AppointmentStore.State('appointmentType') appointmentType!: string;
    @AppointmentStore.State('client') client!: ClientType | null;
    @AppointmentStore.State('branchAddress') branchAddress!: BranchAddressType | null;
    @AppointmentStore.State('appointmentCategory') appointmentCategory!: AppointmentCategoryType | null;
    @AppointmentStore.State('placeParsedData') placeParsedData!: ParsedAddressData;
    @AppointmentStore.State('appointmentOnSiteData') appointmentOnSiteData!: AppointmentOnSiteDataType | null;
    @AppointmentStore.State('appointmentDataToUpdate') appointmentDataToUpdate!: AppointmentSearchType | null;
    @AppointmentStore.Mutation('cleanViewedSlotsList') cleanViewedSlotsList!: () => void;
    @AppointmentStore.State('branchData') branchData!: BranchSitesDataType;
    @AppointmentStore.State('locationName') locationName!: CategoryLocationSkillNameType;

    @CalendarStore.Action('actionAvailabilitiesToStore') actionAvailabilitiesToStore!: (data: SetAvailabilitiesToStoreType) => void;
    @CalendarStore.Mutation('setCalendarViewData') setCalendarViewData!: (calendar_view_data: CalendarViewSettingsType) => void;
    @CalendarStore.Mutation('setUserOnCalendar') setUserOnCalendar!: (value: boolean) => void;
    @CalendarStore.Mutation('clearCalendarState') clearCalendarState!: () => void;
    @CalendarStore.Mutation('addEventToStore') addEventToStore!: (event: ConsultEventType) => void;
    @CalendarStore.State('calendar_view_data') calendarViewData!: CalendarViewSettingsType | null;
    @CalendarStore.State('usersList') usersList!: Array<UserListType>;
    @CalendarStore.State('post_code') post_code!: string;
    @CalendarStore.State('country_iso') country_iso!: string;
    @CalendarStore.State('post_code_availabilities') post_code_availabilities!: Array<BookingEventType | ConsultEventType>;

    @CommonStore.Mutation('setFixedBgStatus') setFixedBgStatus!: Function;
    @CommonStore.Mutation('setHideBgStatus') setHideBgStatus!: Function;

    loading: boolean = true;
    startLoading: boolean = true;
    date: Date = new Date();
    calendarDate: Date = new Date();
    previousDate: string | null = null;
    dates: Array<Date> = [];
    datesInterval = 7;
    planTableKey: number = 1;
    showForm: boolean = false;
    isUpdateAppointmentPopup: boolean = false;
    isUpdateNowWithCurrentAdvisor: boolean = false;
    isAdvisorNeedToUpdate: boolean = false;
    formData: CompanyEventType | WorkersEventType | EventsType | null = null;
    value: string | null | CompanyData = null;
    options: Array<CompanyData> | null = [companyDataProps];
    meta: BaseMetaType | null = null;
    users: Array<UserListType> = [];
    calendarUsers: Array<CalendarViewSettingsUsertype> = [];
    windowWidth: number | null = null;
    userFilterId: string | null = null;
    branchSiteId: string | null = null;
    showCalendar: boolean = false;
    monthDataLoading: boolean = false;
    showTable: boolean = true;
    pageLoading: boolean = false;
    slotsLoading: boolean = false;
    information: CalendarViewSettingsResponseType | null = null;
    branchVideoRoomId: string | null = null;
    page: number = 1;
    appointmentsData: Array<BookingEventType | ConsultEventType> = [];

    $refs!: {
        leftBar: HTMLElement
    };

    get bookingInformation(): Array<informationDataType> {
        const appointmentDataArray: Array<informationDataType> = [];
        if (this.appointmentCategory && this.appointmentCategory.title) {
            appointmentDataArray.push({
                title: this.$i18n.t('CALENDAR.APPOINTMENT.LABEL'),
                aside: capitalizeAll(this.appointmentCategory.title),
            });
        }
        if (this.appointmentCategory && this.appointmentCategory.duration) {
            appointmentDataArray.push({
                title: this.$i18n.t('CALENDAR.DURATION.LABEL'),
                aside: this.appointmentCategory.duration,
            });
        }
        // @ts-ignore-next-line
        if (this.locationName && this.locationName[this.labelKey]) {
            appointmentDataArray.push({
                title: this.$i18n.t('CALENDAR.METHOD.LABEL'),
                // @ts-ignore-next-line
                aside: this.locationName[this.labelKey],
            });
        }
        if (this.placeParsedData) {
            appointmentDataArray.push({
                title: this.$i18n.t('CALENDAR.LOCATION.LABEL'),
                aside: this.isBookCalendarOnSiteInBranch && this.branchAddress ? this.branchAddress.address : this.placeParsedData.calizyAddress,
            });
        }
        if (this.branchData && this.branchData.address) {
            appointmentDataArray.push({
                title: this.$i18n.t('CALENDAR.LOCATION.BRANCH.LABEL'),
                aside: this.branchData.address,
            });
        }
        return appointmentDataArray;
    }

    get showRoomList(): boolean {
        return Boolean(
            FLOWS_WITH_ROOM_ID_REQUIRE.includes(this.appointmentType) &&
                this.branchVideoRooms &&
                this.branchVideoRooms.length
        );
    }

    get currentTime(): str {
        return `${dayjs(this.dates[0]).format(`MMM D`)} - ${dayjs(this.dates[this.dates.length - 1]).format(`MMM D`)}`;
    }

    get calendarReservedSlotsData(): Array<any> {
        return this.$store.state.CalendarPageStore.calendar_reserved_slots;
    }

    get branchVideoRooms(): Array<BranchAddressVideoRoomType> {
        if (!this.branchAddress ||
            !this.branchAddress.branch_video_rooms ||
            !this.branchAddress.branch_video_rooms.length) {
            return [];
        }
        return this.branchAddress.branch_video_rooms;
    }

    get startBranchVideoRoomId(): string | null {
        return this.branchVideoRooms.length ? this.branchVideoRooms[0].id : null;
    }

    get dateUntil() {
        if (this.isImhAccount && this.appointmentDataToUpdate && this.appointmentDataToUpdate.dt_update_slots_until) {
            return this.appointmentDataToUpdate.dt_update_slots_until;
        }

        return null;
    }

    toggleServicePointView(): void {
        // @ts-ignore-next-line
        this.$router.replace({
            name: this.$route.name,
            params: {
                param: this.$route.params.param === SHIFTS_CALENDAR_PARAM ? undefined : SHIFTS_CALENDAR_PARAM,
                type: this.$route.params.type,
                id: this.$route.params.id,
            },
        });
        this.updateDates(this.date);
    }

    handleResize(): void {
        this.windowWidth = window.innerWidth;
        this.showCalendar = this.windowWidth > 992;
    }

    setActiveUser(userId: string | null): void {
        this.userFilterId = userId;
        const payload: GetMonthDataPayload = {
            date: this.calendarDate,
            isUserFilter: true,
        };
        if (this.userFilterId) {
            payload.user_id = this.userFilterId || '';
            payload.duration = this.appointmentCategory!.duration;
        }
        this.getMonthData(payload);
    }

    goBack(): void | Promise<Route> {
        if (!CONSULT_MONTH_VIEW_CALENDARS.includes(this.$route.params.type)) {
            this.$router.go(-1);
        }
        return this.$router.push(`/consult/${this.$route.params.type}/${this.$route.params.id}`);
    }

    toggleUserActive(user: CalendarViewSettingsUsertype): void {
        Vue.set(this.calendarUsers, this.calendarUsers.findIndex(item => item.id === user.id), { ...user, ...{ isActive: !user.isActive }});
    }

    async setEventToStore(event: ConsultEventType): Promise<void> {
        this.addEventToStore(event);
        await this.$nextTick();
        this.planTableKey += 1;
    }

    goToday(): void {
        this.changeDay({ delta: 0, date: dayjs(new Date()).startOf('day').toDate() });
    }

    changeDay({ delta, date }: { delta: number, date: null | Date }): void {
        if (date) {
            const datesByCalendarSettings = this.calculateDatesByCalendarSettings;
            const dayIndex = datesByCalendarSettings.findIndex(item => dayjs(date).isSame(item, 'day'));
            if (this.isConsultCalendarType) {
                this.date = this.getDate({ delta, date });
            } else if (dayIndex !== undefined && datesByCalendarSettings[dayIndex + delta]) {
                this.date = datesByCalendarSettings[dayIndex + delta];
            } else {
                const previousWeekDate: Date = this.getDate({ delta: -6, date });
                const previousDates = this.prepareDates(previousWeekDate);
                this.date = previousDates[previousDates.length - 1];
            }
        }
        this.updateDates(this.date);
    }

    openForm(data: CompanyEventType | WorkersEventType | EventsType): void {
        this.showForm = true;
        this.showCalendar = true;
        this.setFixedBgStatus(true);
        this.setHideBgStatus(true);
        this.formData = data;
        if (!this.showCalendar) {
            this.showCalendar = true;
        }
    }

    get calculateDatesByCalendarSettings(): Array<Date> {
        const dates: Array<Date> = clone(this.dates);
        if (this.isConsultCalendarType) return dates;
        const result: Array<Date> = [];
        if (!this.calendarViewData) return result;
        if (![
            this.calendarViewData.monday,
            this.calendarViewData.tuesday,
            this.calendarViewData.wednesday,
            this.calendarViewData.thursday,
            this.calendarViewData.friday,
            this.calendarViewData.saturday,
            this.calendarViewData.sunday,
        ].some(item => item)) {
            this.$router.push({ path: `/settings/calendar-view` });
            return dates;
        }
        const dayKeys = [`monday`, `tuesday`, `wednesday`, `thursday`, `friday`, `saturday`, `sunday`];
        for (let i = 0; i < dates.length; i++) {
            // @ts-ignore-next-line
            if (this.calendarViewData[dayKeys[dates[i].getDay()]]) {
                result.push(dates[i]);
            }
        }
        return result;
    }

    closeForm(): void {
        this.showForm = false;
        this.setFixedBgStatus(false);
        this.setHideBgStatus(false);
    }

    updateRoomId(id: string): void {
        this.branchVideoRoomId = id;
        const payload: GetMonthDataPayload = {
            date: this.calendarDate,
            ...(this.user_id && { isUserFilter: true }),
        };
        if (this.userFilterId) {
            payload.user_id = this.userFilterId || '';
            payload.duration = this.appointmentCategory!.duration;
        }
        this.getMonthData(payload);
    }

    async updateDates(date: Date | null = null): Promise<void> {
        this.loading = true;
        this.userFilterId = null;
        if (this.windowWidth && this.windowWidth < 992) {
            this.showCalendar = false;
        }

        this.dates = this.prepareDates(date || this.date);
        if (date) {
            this.calendarDate = date;
        }
        if (date && this.previousDate !== new Date(date).toLocaleString('default', { month: 'long' })) {
            this.updateAppointmentData();
            this.getMonthData({ date });
        }
    }

    prepareDates(date?: Date): Array<Date> {
        const dates = [];
        const localDate = this.getDayDateAtNight(date || new Date());
        for (let delta = 0; delta < this.datesInterval; delta++) {
            dates.push(this.getDate({ delta, date: localDate }));
        }
        return dates;
    }

    changeCalendarMonth(data: { year: number, month: number, flag: number, vm: any, sibling: undefined }): void {
        const date: Date = new Date(data.year, data.month + data.flag + 1, 0);
    }

    async fetchCalendarInformation(id: string): Promise<void> {
        try {
            let url;
            if (this.isConsultCalendarCompany) {
                url = `companies/${id}/calendar_detail`;
            } else if (this.isConsultCalendarHub) {
                url = `hubs/${id}/calendar_detail`;
            } else if (this.isConsultCalendarBranch) {
                url = `sites/${id}/calendar_detail`;
            } else if (this.isConsultCalendarUser) {
                url = `users/${id}/calendar_detail`;
            }
            if (url) {
                // @ts-ignore-next-line
                const response = await CalendarApi.getCalendarInformation(url);
                this.information = response.data;
                this.calendarUsers = response.data.users.map(user => ({ ...user, isActive: true }));
            }
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    async fetchServicePointsUsers(id: string): Promise<void> {
        try {
            const data = await CalendarApi.getServicePointUsers(id);
            // @ts-ignore
            this.calendarUsers = data.service_point_advisors.map(user => ({ ...user, isActive: true }));
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    get appointments(): Array<BookingEventType | ConsultEventType> {
        if (this.isBookCalendarType) {
            return (this.post_code_availabilities as Array<BookingEventType>).filter((item: BookingEventType) => {
                return +this.parseToUTCDate(item.slot) > +new Date();
            });
        }
        if (this.calendarUsers && this.calendarUsers.length) {
            const activeUserIds = this.calendarUsers.filter(user => user.isActive).map(user => user.id);
            const field = this.isConsultCalendarServicePointShifts ? 'user_id' : 'assigned_to_id';
            // @ts-ignore
            return (this.post_code_availabilities as Array<ConsultEventType>).filter(item => activeUserIds.includes(item[field]!));
        }
        return this.post_code_availabilities;
    }

    get currentRoomId(): string | null {
        return this.branchVideoRoomId || this.startBranchVideoRoomId;
    }

    async getMonthData({ date, user_id = '', appointment_category = '', duration = 0, isUserFilter = false }: GetMonthDataPayload) {
        if (!this.calendarDate) return;
        const todayNight: string = dateToString(date || new Date(this.calendarDate.getTime()), true);
        const copyDate = (time: string) => new Date(time);
        const dt_week_start_date: Date = copyDate(todayNight);
        const dt_week_end_date: Date = getDateWithDelayInDays(copyDate(todayNight), 7);
        const dt_week_start: string = dateToString(dt_week_start_date, true);
        let dt_week_end: string = dateToString(dt_week_end_date, true);
        if (this.isImhAccount && this.appointmentDataToUpdate && this.appointmentDataToUpdate.dt_update_slots_until) {
            dt_week_end = dateToString(new Date(this.appointmentDataToUpdate.dt_update_slots_until as string));
        }
        if (isUserFilter) {
            this.slotsLoading = true;
        } else {
            this.monthDataLoading = true;
        }
        await this.fetchWeekData(
            dt_week_start,
            dt_week_end,
            { date, user_id, appointment_category, duration }
        );
        this.loading = false;
        setTimeout(() => { this.monthDataLoading = false; this.slotsLoading = false; }, 200);
    }

    async fetchWeekData(dt_week_start: string, dt_week_end: string, data?: GetMonthDataPayload): Promise<void> {
        if (this.isBookCalendarType) {
            if (this.isBookCalendarOnPhone) {
                if (!this.post_code || !this.appointmentCategory) {
                    this.$_NoDataMixin_bookFlowNoData();
                    return;
                }

                try {
                    const payload: AvailabilitiesByPostCodePayloadType = {
                        from: dt_week_start,
                        to: dt_week_end,
                        post_code: this.post_code,
                    };
                    if (this.country_iso) {
                        payload.post_code = `${payload.post_code}/${this.country_iso}`;
                    }
                    const ignoreUpdateUsers = Boolean(data && data.user_id);
                    if (ignoreUpdateUsers) {
                        payload.user_id = data!.user_id;
                        payload.duration = data!.duration;
                    } else {
                        payload.appointment_category_id = this.appointmentCategory!.id;
                    }
                    const { availabilities, technicians } = await AppointmentWebApi.getAvailabilitiesByOnPhone(payload);
                    this.actionAvailabilitiesToStore({ availabilities, technicians, setUsers: !ignoreUpdateUsers, isBookingEventType: this.isBookCalendarType });
                } catch (ajax) {
                    this.$router.push({ path: `/appointment/postcode` });
                    this.sentNotif(ajax.response);
                }
            } else if (this.isBookCalendarByOnlineVideo) {
                if (!this.post_code || !this.appointmentCategory) {
                    this.$_NoDataMixin_bookFlowNoData();
                    return;
                }
                try {
                    const payload: AvailabilitiesByPostCodePayloadType = {
                        from: dt_week_start,
                        to: dt_week_end,
                    };
                    const ignoreUpdateUsers = Boolean(data && data.user_id);
                    if (ignoreUpdateUsers) {
                        payload.user_id = data!.user_id;
                        payload.duration = data!.duration;
                        payload.post_code = this.post_code;
                        payload.appointment_category_id = this.appointmentCategory!.id;
                    } else {
                        payload.post_code = this.post_code;
                        payload.appointment_category_id = this.appointmentCategory!.id;
                    }
                    if (this.country_iso) {
                        payload.post_code = `${payload.post_code}/${this.country_iso}`;
                    }
                    const { availabilities, technicians } = await AppointmentWebApi.getAvailabilitiesByOnlineVideo(payload);
                    this.actionAvailabilitiesToStore({ availabilities, technicians, setUsers: !ignoreUpdateUsers, isBookingEventType: this.isBookCalendarType });
                } catch (ajax) {
                    this.$router.push({ path: `/appointment/postcode` });
                    this.sentNotif(ajax.response);
                }
            } else if (this.isBookCalendarInBranchFaceToFace) {
                if (!this.branchAddress || !this.appointmentCategory) {
                    this.$_NoDataMixin_bookFlowNoData();
                    return;
                }
                try {
                    this.branchSiteId = this.branchAddress!.id;
                    const payload: AvailabilitiesBranchFaceToFacePayloadType = {
                        from: dt_week_start,
                        to: dt_week_end,
                    };
                    const ignoreUpdateUsers = Boolean(data && data.user_id);
                    payload.site_id = this.branchAddress!.id;
                    if (ignoreUpdateUsers) {
                        payload.user_id = data!.user_id;
                        payload.duration = data!.duration || this.appointmentCategory.duration;
                        payload.appointment_category_id = this.appointmentCategory!.id;
                    } else {
                        payload.appointment_category_id = this.appointmentCategory!.id;
                    }

                    if (this.country_iso && this.calculateRouteForBranchesBooking) {
                        payload.country_iso = this.country_iso;
                    }
                    const { availabilities, technicians } = await AppointmentWebApi.getAvailabilitiesByBranchFaceToFacePublic(payload, this.calculateRouteForBranchesBooking);
                    this.actionAvailabilitiesToStore({ availabilities, technicians, setUsers: !ignoreUpdateUsers, isBookingEventType: this.isBookCalendarType });
                } catch (ajax) {
                    // this.$router.push({ path: `/appointment/postcode` });
                    this.sentNotif(ajax.response);
                }
            } else if (this.isBookCalendarInServicePoints) {
                if (!this.branchAddress || !this.appointmentCategory) {
                    this.$_NoDataMixin_bookFlowNoData();
                    return;
                }
                try {
                    this.branchSiteId = this.branchAddress!.id;
                    const payload: AvailabilitiesBranchFaceToFacePayloadType = {
                        from: dt_week_start,
                        to: dt_week_end,
                    };
                    const ignoreUpdateUsers = Boolean(data && data.user_id);
                    payload.site_id = this.branchAddress!.id;
                    if (ignoreUpdateUsers) {
                        payload.user_id = data!.user_id;
                        payload.duration = data!.duration;
                    } else {
                        payload.appointment_category_id = this.appointmentCategory!.id;
                    }
                    const { availabilities, technicians } = await AppointmentWebApi.getAvailabilitiesInServicePoint(payload);
                    this.actionAvailabilitiesToStore({ availabilities, technicians, setUsers: !ignoreUpdateUsers, isBookingEventType: this.isBookCalendarType });
                } catch (ajax) {
                    // this.$router.push({ path: `/appointment/postcode` });
                    this.sentNotif(ajax.response);
                }
            } else if (this.isBookCalendarBranchByPhonePublic) {
                if (!this.branchAddress || !this.appointmentCategory) {
                    this.$_NoDataMixin_bookFlowNoData();
                    return;
                }
                try {
                    this.branchSiteId = this.branchAddress!.id;
                    const payload: AvailabilitiesBranchFaceToFacePayloadType = {
                        from: dt_week_start,
                        to: dt_week_end,
                    };
                    const ignoreUpdateUsers = Boolean(data && data.user_id);
                    payload.site_id = this.branchAddress!.id;
                    if (ignoreUpdateUsers) {
                        payload.user_id = data!.user_id;
                        payload.duration = data!.duration;
                    } else {
                        payload.appointment_category_id = this.appointmentCategory!.id;
                    }
                    const { availabilities, technicians } = await AppointmentWebApi.getAvailabilitiesByBranchPhonePublic(payload);
                    this.actionAvailabilitiesToStore({ availabilities, technicians, setUsers: !ignoreUpdateUsers, isBookingEventType: this.isBookCalendarType });
                } catch (ajax) {
                    // this.$router.push({ path: `/appointment/postcode` });
                    this.sentNotif(ajax.response);
                }
            } else if (this.isBookCalendarBranchByVideoPublic) {
                if (!this.branchAddress || !this.appointmentCategory) {
                    this.$_NoDataMixin_bookFlowNoData();
                    return;
                }
                try {
                    this.branchSiteId = this.branchAddress!.id;
                    const payload: AvailabilitiesBranchFaceToFacePayloadType = {
                        from: dt_week_start,
                        to: dt_week_end,
                    };
                    const ignoreUpdateUsers = Boolean(data && data.user_id);
                    payload.site_id = this.branchAddress!.id;
                    if (ignoreUpdateUsers) {
                        payload.user_id = data!.user_id;
                        payload.duration = data!.duration;
                    } else {
                        payload.appointment_category_id = this.appointmentCategory!.id;
                    }
                    const { availabilities, technicians } = await AppointmentWebApi.getAvailabilitiesByBranchVideoPublic(payload);
                    this.actionAvailabilitiesToStore({ availabilities, technicians, setUsers: !ignoreUpdateUsers, isBookingEventType: this.isBookCalendarType });
                } catch (ajax) {
                    // this.$router.push({ path: `/appointment/postcode` });
                    this.sentNotif(ajax.response);
                }
            } else if (this.isBookCalendarBranchPrivateVideo) {
                try {
                    if (!this.branchAddress || !this.branchAddress.branch_video_rooms || !this.branchAddress.branch_video_rooms.length) {
                        this.$_NoDataMixin_bookFlowNoData();
                        return;
                    }
                    this.branchSiteId = this.currentRoomId;
                    const payload: AvailabilitiesVideoconferencePayloadType = {
                        from: dt_week_start,
                        to: dt_week_end,
                        post_code: this.post_code || parseAddress(this.branchAddress!.address).zip_code,
                        room_id: (this.currentRoomId as string),
                    };
                    const ignoreUpdateUsers = Boolean(data && data.user_id);
                    payload.site_id = this.branchAddress!.id;
                    if (ignoreUpdateUsers) {
                        payload.user_id = data!.user_id;
                        payload.duration = data!.duration;
                    } else {
                        payload.appointment_category_id = this.appointmentCategory!.id;
                    }
                    const { availabilities, technicians } = await AppointmentWebApi.getAvailabilitiesByBranchFaceToFacePrivate(payload);
                    this.actionAvailabilitiesToStore({ availabilities, technicians, setUsers: !ignoreUpdateUsers, isBookingEventType: this.isBookCalendarType });
                } catch (ajax) {
                    // this.$router.push({ path: `/appointment/postcode` });
                    this.sentNotif(ajax.response);
                }
            } else if (this.isBookCalendarInBranchVideoconference) {
                try {
                    if (!this.branchAddress || !this.branchAddress.branch_video_rooms || !this.branchAddress.branch_video_rooms.length) {
                        this.$_NoDataMixin_bookFlowNoData();
                        return;
                    }
                    this.branchSiteId = this.currentRoomId;
                    const payload: AvailabilitiesVideoconferencePayloadType = {
                        from: dt_week_start,
                        to: dt_week_end,
                        post_code: this.post_code || parseAddress(this.branchAddress!.address).zip_code,
                        room_id: (this.currentRoomId as string),
                    };
                    const ignoreUpdateUsers = Boolean(data && data.user_id);
                    payload.site_id = this.branchAddress!.id;
                    if (ignoreUpdateUsers) {
                        payload.user_id = data!.user_id;
                        payload.duration = data!.duration;
                    } else {
                        payload.appointment_category_id = this.appointmentCategory!.id;
                    }
                    const { availabilities, technicians } = await AppointmentWebApi.getAvailabilitiesByBranchClientVideoPrivate(payload);
                    this.actionAvailabilitiesToStore({ availabilities, technicians, setUsers: !ignoreUpdateUsers, isBookingEventType: this.isBookCalendarType });
                } catch (ajax) {
                    // this.$router.push({ path: `/appointment/postcode` });
                    this.sentNotif(ajax.response);
                }
            } else if (this.isBookCalendarBranchByPhonePrivate) {
                try {
                    if (!this.branchAddress || !this.branchAddress.branch_video_rooms || !this.branchAddress.branch_video_rooms.length) {
                        this.$_NoDataMixin_bookFlowNoData();
                        return;
                    }
                    this.branchSiteId = this.currentRoomId;
                    const payload: AvailabilitiesVideoconferencePayloadType = {
                        from: dt_week_start,
                        to: dt_week_end,
                        post_code: this.post_code || parseAddress(this.branchAddress!.address).zip_code,
                        room_id: (this.currentRoomId as string),
                    };
                    const ignoreUpdateUsers = Boolean(data && data.user_id);
                    payload.site_id = this.branchAddress!.id;
                    if (ignoreUpdateUsers) {
                        payload.user_id = data!.user_id;
                        payload.duration = data!.duration;
                    } else {
                        payload.appointment_category_id = this.appointmentCategory!.id;
                    }
                    const { availabilities, technicians } = await AppointmentWebApi.getAvailabilitiesByBranchPhonePrivate(payload);
                    this.actionAvailabilitiesToStore({ availabilities, technicians, setUsers: !ignoreUpdateUsers, isBookingEventType: this.isBookCalendarType });
                } catch (ajax) {
                    // this.$router.push({ path: `/appointment/postcode` });
                    this.sentNotif(ajax.response);
                }
            } else if (this.isBookCalendarBranchByVideoPrivate) {
                try {
                    if (!this.branchAddress || !this.branchAddress.branch_video_rooms || !this.branchAddress.branch_video_rooms.length) {
                        this.$_NoDataMixin_bookFlowNoData();
                        return;
                    }
                    this.branchSiteId = this.currentRoomId;
                    const payload: AvailabilitiesVideoconferencePayloadType = {
                        from: dt_week_start,
                        to: dt_week_end,
                        post_code: this.post_code || parseAddress(this.branchAddress!.address).zip_code,
                        room_id: (this.currentRoomId as string),
                    };
                    const ignoreUpdateUsers = Boolean(data && data.user_id);
                    payload.site_id = this.branchAddress!.id;
                    if (ignoreUpdateUsers) {
                        payload.user_id = data!.user_id;
                        payload.duration = data!.duration;
                    } else {
                        payload.appointment_category_id = this.appointmentCategory!.id;
                    }
                    const { availabilities, technicians } = await AppointmentWebApi.getAvailabilitiesByBranchVideoPrivate(payload);
                    this.actionAvailabilitiesToStore({ availabilities, technicians, setUsers: !ignoreUpdateUsers, isBookingEventType: this.isBookCalendarType });
                } catch (ajax) {
                    // this.$router.push({ path: `/appointment/postcode` });
                    this.sentNotif(ajax.response);
                }
            } else if (this.isBookCalendarOnSite) {
                if (!this.appointmentOnSiteData) {
                    this.$_NoDataMixin_bookFlowNoData();
                    return;
                }
                try {
                    const payload: AvailabilitiesOnSitePayloadType = {
                        from: dt_week_start,
                        to: dt_week_end,
                        appointment_id: this.appointmentOnSiteData!.id,
                        post_code: this.post_code || this.placeParsedData.post_code,
                    };
                    const ignoreUpdateUsers = Boolean(data && data.user_id);
                    if (ignoreUpdateUsers) {
                        payload.user_id = data!.user_id;
                    }
                    if (this.appointmentOnSiteData && this.appointmentOnSiteData.country_iso) {
                        payload.country_iso = this.appointmentOnSiteData.country_iso;
                    }
                    const { availabilities, technicians = [] } = await AppointmentWebApi.getAvailabilitiesOnSite(payload);
                    this.actionAvailabilitiesToStore({ availabilities, technicians, setUsers: !ignoreUpdateUsers, isBookingEventType: this.isBookCalendarType });
                } catch (ajax) {
                    this.sentNotif(ajax.response);
                }
            } else if (this.isBookCalendarOnSiteInBranch) {
                if (!this.appointmentOnSiteData) {
                    this.$_NoDataMixin_bookFlowNoData();
                    return;
                }
                try {
                    const payload: AvailabilitiesOnSiteInBranchPayloadType = {
                        from: dt_week_start,
                        to: dt_week_end,
                        appointment_id: this.appointmentOnSiteData!.id,
                        post_code: this.post_code,
                        site_id: this.appointmentOnSiteData!.fetched_details_raw.site_id,
                    };
                    const ignoreUpdateUsers = Boolean(data && data.user_id);
                    if (ignoreUpdateUsers) {
                        payload.user_id = data!.user_id;
                    }
                    if (this.appointmentOnSiteData && this.appointmentOnSiteData.country_iso) {
                        payload.country_iso = this.appointmentOnSiteData.country_iso;
                    }
                    const { availabilities, technicians = [] } = await AppointmentWebApi.getAvailabilitiesOnSiteInBranch(payload);
                    this.actionAvailabilitiesToStore({ availabilities, technicians, setUsers: !ignoreUpdateUsers, isBookingEventType: this.isBookCalendarType });
                } catch (ajax) {
                    this.sentNotif(ajax.response);
                }
            }
        } else if (this.isConsultCalendarType) {
            if (this.isConsultCalendarBranch) {
                try {
                    const availabilities = await AppointmentWebApi.getBranchAppointmentsData({
                        from: dt_week_start,
                        to: dt_week_end,
                        branch_id: this.$route.params.id,
                    });
                    this.actionAvailabilitiesToStore({ availabilities });
                } catch (ajax) {
                    // this.$router.push({ path: `/appointment/postcode` });
                    this.sentNotif(ajax.response);
                }
            } if (this.isConsultCalendarHub) {
                try {
                    const appointmentResponse = await AppointmentWebApi.getHubAppointmentsData({
                        from: dt_week_start,
                        to: dt_week_end,
                        hub_id: this.$route.params.id,
                        page: this.page,
                    });

                    const { appointments, meta } = appointmentResponse;

                    if (meta && meta.next_page) {
                        this.page = meta.next_page;
                        await this.getMonthData({ date: this.calendarDate });
                    }

                    this.appointmentsData = [...this.appointmentsData, ...appointments];
                    this.actionAvailabilitiesToStore({ availabilities: this.appointmentsData });
                } catch (ajax) {
                    // this.$router.push({ path: `/appointment/postcode` });
                    this.sentNotif(ajax.response);
                }
            } else if (this.isConsultCalendarUser) {
                try {
                    const payload = {
                        user_id: this.$route.params.id,
                        from: dt_week_start,
                        to: dt_week_end,
                        page: this.page,
                    };
                    const appointmentResponse = await AppointmentWebApi.getUserAppointmentsData(payload);
                    const { appointments, meta } = appointmentResponse;

                    if (meta && meta.next_page) {
                        this.page = meta.next_page;
                        await this.getMonthData({ date: this.calendarDate });
                    }

                    this.appointmentsData = [...this.appointmentsData, ...appointments];
                    this.actionAvailabilitiesToStore({ availabilities: this.appointmentsData });
                } catch (ajax) {
                    this.sentNotif(ajax.response);
                }
            } else if (this.isConsultCalendarCompany) {
                try {
                    const payload = {
                        company_id: this.$route.params.id,
                        from: dt_week_start,
                        to: dt_week_end,
                    };
                    const availabilities = await AppointmentWebApi.getCompanyAppointmentsData(payload);
                    this.actionAvailabilitiesToStore({ availabilities });
                } catch (ajax) {
                    this.sentNotif(ajax.response);
                }
            } else if (this.isConsultCalendarServicePoint) {
                if (this.isConsultCalendarServicePointShifts) {
                    const response: ShiftSlotsResponseType = await EventsWebApi.getShiftsSlotsForDate({
                        from: dt_week_start,
                        to: dt_week_end,
                        sp_id: (this.$route.params.id as string),
                    });
                    // @ts-ignore-next-line
                    this.actionAvailabilitiesToStore({ availabilities: response.service_point_shifts });
                } else {
                    try {
                        const appointmentResponse = await AppointmentWebApi.getServicePointAppointmentsData({
                            from: dt_week_start,
                            to: dt_week_end,
                            sp_id: this.$route.params.id,
                            page: this.page,
                        });
                        const { appointments, meta } = appointmentResponse;

                        if (meta && meta.next_page) {
                            this.page = meta.next_page;
                            await this.getMonthData({ date: this.calendarDate });
                        }

                        this.appointmentsData = [...this.appointmentsData, ...appointments];
                        this.actionAvailabilitiesToStore({ availabilities: this.appointmentsData });
                    } catch (ajax) {
                        this.sentNotif(ajax.response);
                    }
                }
            }
        }
    }

    async getCalendarView(): Promise<void> {
        try {
            const data = await SettingsWebApi.getCalendarViewData();
            this.setCalendarViewData(data.calendar_view_settings[0]);
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    async created(): Promise<void> {
        this.startLoading = true;
        if (!this.calendarViewData && this.isBookCalendarType) {
            await this.getCalendarView();
        }
        this.updateDates();
        this.handleResize();
        this.showTable = false;
        const promises: Array<Promise<any>> = [
            this.getMonthData({ date: this.date }),
        ];
        if (this.isConsultCalendarType && this.$route.params.type) {
            if (this.isConsultCalendarServicePoint) {
                promises.push(this.fetchServicePointsUsers(this.$route.params.id));
            } else {
                promises.push(this.fetchCalendarInformation(this.$route.params.id));
            }
        }
        await Promise.all(promises);
        this.showTable = true;
        this.setUserOnCalendar(true);
        this.loading = false;
        this.startLoading = false;

        if (this.appointmentDataToUpdate) {
            if (this.appointmentDataToUpdate.room_id &&
                this.branchAddress &&
                this.branchAddress.branch_video_rooms &&
                !this.appointmentDataToUpdate.is_update_from_start) {
                const [branch_room] = this.branchAddress.branch_video_rooms.filter(room => room.id === this.appointmentDataToUpdate!.room_id);
                this.branchVideoRoomId = branch_room ? branch_room.id : null;
            }
            if (this.appointmentDataToUpdate.advisor_technician_id && this.appointmentDataToUpdate.is_update_from_start) {
                const advisorsIds = this.usersList.map((user: UserListType) => user.id);

                if (advisorsIds.includes(this.appointmentDataToUpdate.advisor_technician_id)) {
                    this.isUpdateAppointmentPopup = true;
                }
            }
        }
    }

    closeUpdatePopup(value: boolean): void {
        this.isUpdateAppointmentPopup = value;
    }

    updateNowState(state: boolean): void {
        this.isUpdateNowWithCurrentAdvisor = state;
    }

    advisorNeedToUpdateState(state: boolean): void {
        this.isAdvisorNeedToUpdate = state;
    }

    updateEvents(): void {
        this.updateAppointmentData();
        this.getMonthData({ date: this.calendarDate });
        this.planTableKey += this.planTableKey;
    }

    updateAppointmentData(): void {
        this.page = 1;
        this.appointmentsData = [];
    }

    beforeDestroy(): void {
        window.removeEventListener('resize', this.handleResize);
        this.$store.commit('CALENDAR_DATE_CLEAR');
        this.information = null;
        this.cleanViewedSlotsList();
        this.actionAvailabilitiesToStore({ availabilities: [], technicians: [], setUsers: true, isBookingEventType: true });
        this.updateAppointmentData();
    }
}
</script>

<style lang='sass'>
body .h-height-auto-force
    height: auto !important
    min-height: auto !important

.h-table-cell
    display: table-cell

.h-table
    display: table

.h-white-space-nowrap
    white-space: nowrap

.b-appointment-main
    flex: 1
    height: 100%
    background-color: #fff

    .b-select-company
        &__calendar
            .multiselect__option
                padding: 17px !important
                display: table !important
                width: 100%

            .multiselect__element
                display: table
                width: 100%

    .fw-select-tooltip .multiselect__content
        overflow-y: auto !important

    .fw-select-tooltip .multiselect__content-wrapper
        display: block
        overflow-y: auto !important
        margin-top: 10px
        width: auto !important

    .multiselect__tags
        padding: 0!important
        max-height: 30px!important
        min-height: 30px!important

    &__title
        font-size: 20px

    &__today-button
        width: 83px
        height: 28px

    &__show-button
        position: fixed
        bottom: 80px
        left: 50px
        padding: 10px 12px
        border-radius: 8px
        background-color: #213F6B
        z-index: 31
        width: auto

    .multiselect__content-wrapper
        min-width: 280px
        min-height: 80px

    .fw-select__caret
        right: 5px

    .multiselect__placeholder
        padding-right: 20px

    .multiselect:after
        right: -1px!important

.fw-horizontal-scroll__inner, .b-calendar-page__height-wrapper
    height: 100vh !important

.fw-horizontal-scroll__content
    height: 100%!important

.b-calendar-page
    &__wrapper
        width: 100%
        height: 100%
        display: flex
        align-items: center
        overflow: hidden

        &--is-open
            .b-appointment-main__show-button
                svg
                    transform: rotate(180deg)

    &__info-link
        &:hover
            opacity: 0.8

    &__height-wrapper
        @include media('<=phone')
            .fw-horizontal-scroll__shadow
                width: 40px !important
                height: 40px
                top: -50px
                bottom: 0
                margin: auto
                background: none !important

                & > span
                    margin-left: 0
                    margin-right: 0

            .fw-horizontal-scroll__shadow--right
                right: 0 !important

.b-calendar-page__corner-fix
    display: block

    @include media('<=phone')
        max-width: 100vw
        overflow: auto

    .fw-horizontal-scroll__shadow--left
        left: 60px
        z-index: 5

    &:after
        content: ''
        top: 0
        left: 0
        width: 65px
        height: 40px
        z-index: 31
        background-color: #fff
        position: absolute

        @include media('<tablet')
            display: none

@include media('<tablet')
    .b-appointment
        &-left-bar
            overflow: scroll
            position: fixed
            top: 0
            width: 100%
            height: 100%
            z-index: 31
            padding: 20px 20px 10px

    .b-appointment-main
        &__header
            flex-flow: column nowrap
            margin: 0
            padding: 5px 0

        &__title
            font-size: 16px

.b-fw-spin-loader-wrapper
    width: 100%
    height: 100%
    position: fixed
    left: 0
    top: 0
    z-index: 9999
    display: flex
    align-items: center
    justify-content: center

.b-appointment-main
    .fw-horizontal-scroll__content
        @include media('>phone')
            min-width: calc(100vw - 350px) !important
            max-width: calc(100vw - 350px) !important
            width: calc(100vw - 350px) !important

.b-calendar-page__header-nav__wrapper
    padding: 20px 60px

    @include media('<=phone')
        padding: 10px 10px
</style>
