<template>
<div>
    <div class='h-text-center h-pt-30'>
        <h1>
            {{ $t('SELECT.POSTCODE.APPOINTMENT.TITLE') }}
        </h1>
    </div>
    <div class='b-society-selection h-flex-center'>
        <form class='h-mt-15 h-flex h-flex-dir-column h-flex-center'>
            <div class='fw-select-base fw-select-white-modern b-classic-width-input'>
                <FwFormInput
                    labelType='label-medium'
                    :title='$t("SELECT.POSTCODE.APPOINTMENT.PLACEHOLDER")'>
                    <FwInput
                        class='qa-select-appointment-post-code h-mb-25'
                        :value='code'
                        type='number'
                        name='text'
                        inputStyle='white-bigger'
                        placeholder='ex: 75001'
                        disabled>
                    </FwInput>
                </FwFormInput>
            </div>
            <FwButton
                size='little'
                borderRadiusType='small-border'
                @click.native='goNext(code)'>
                {{ $t('BUTTON.CONFIRM2') }}
            </FwButton>
            <div class='h-flex h-flex-wrap h-mt-20'>
                <FwButton
                    size='large-xl'
                    color='transparent'
                    borderRadiusType='tiny-border'
                    class='h-width-100p h-mt-10'
                    :fontSize='`16px`'
                    @click.native='$router.push({ path: "/appointment/postcode" })'>
                    <span style='overflow: hidden; text-overflow: ellipsis; white-space: nowrap;'>
                        {{ $t('APPOINTMENT.USE.ANOTHER.POSTCODE') }}
                    </span>
                    <FwIcon
                        class='h-flex h-ml-10'
                        icon='arrow-right'
                        size='12'
                        color='#203f6a'>
                    </FwIcon>
                </FwButton>
            </div>
        </form>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { Validator } from 'simple-vue-validator';
import { namespace } from 'vuex-class';
import ValidationMixin from '@/mixins/validation';
import { AppointmentCategoryType } from '@/types/Appointment';
import DateMixin from '@/mixins/dateMixin';
import NoDataMixin from '@/mixins/no-data-mixin';
import AppointmentMixin from '@/mixins/appointment';
import { ClientType } from '@/types/User';
import { parseAddress } from '@/helpers/address';

const BreadCrumbsStore = namespace('BreadCrumbsStore');
const CalendarStore = namespace('CalendarStore');
const AppointmentStore = namespace('AppointmentStore');

@Component
export default class AppointmentClientPostcodePage extends Mixins(ValidationMixin, DateMixin, NoDataMixin, AppointmentMixin) {
    @AppointmentStore.Mutation('setAppointmentCategory') setAppointmentCategory!: (categoryName: AppointmentCategoryType) => void;
    @CalendarStore.Mutation('setPostCode') setPostCode!: (post_code: string) => void;
    @BreadCrumbsStore.Mutation('setFlowKey') setFlowKey!: (key: string) => void;
    @AppointmentStore.State('appointmentCategory') appointmentCategory!: AppointmentCategoryType;
    @AppointmentStore.State('client') client!: ClientType | null;
    @CalendarStore.State('post_code') post_code!: string;

    loading: boolean = false;
    code: string = '';

    serverErrors: serverErrors = {
        code: null,
    };

    async goNext(code?: string) {
        const localCode = code || this.code;
        if (!localCode) return;
        this.setPostCode(localCode);
        if (this.isOnSiteInBranchFlow) {
            this.$router.push({ path: `/appointment/choose-branch` });
        } else if (this.isOnVideoFlow) {
            this.$router.push({ path: `/appointment/calendar/by-online-video` });
        } else {
            this.$router.push({ path: '/appointment/calendar/on-phone' });
        }
    }

    created() {
        if (this.client && this.client.address) {
            const address = parseAddress(this.client.address);
            if (!(this.isOnPhoneFlow || this.isOnVideoFlow || this.isOnSiteFlow || this.isOnSiteInBranchFlow)) {
                this.goNext(address.zip_code);
            }
        }

        if (this.post_code) {
            this.code = this.post_code;
        }

        if (!this.appointmentCategory) {
            this.$_NoDataMixin_bookFlowNoData();
        }
    }
}
</script>

<style lang='sass'>
.b-society-selection
    .multiselect__option--highlight
        background: transparent!important
    .multiselect__option--highlight span
        color: rgb(47, 89, 151)!important

    .fw-select-base.fw-select-white-modern, .fw-select-white
        box-shadow: none !important
</style>
