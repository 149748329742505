<template>
<div>
    <div class='h-text-center h-pt-30'>
        <h1>
            {{ $t('SELECT.POSTCODE.APPOINTMENT.TITLE') }}
        </h1>
    </div>
    <div class='b-society-selection h-flex-center'>
        <div v-if='isByVideoPhoneMethodPage && isGeoScopeInternationalWithCountries'
             style='width: 320px'
             class='b-address-branch--select fw-select-base h-mt-15 h-mr-20'>
            <FwFormInput
                labelType='label-medium'
                :title='`${$t(`LABEL.COUNTRY`)}`'>
                <FwSelect
                    v-model='country'
                    class='fw-select-white qa-select-appointment-skill'
                    :propsPlaceholder='$t(`LABEL.SELECT.COUNTRY`)'
                    type='white'
                    label='name'
                    :searchable='false'
                    :canNotDelete='true'
                    :options='geoScopeCountries'>
                </FwSelect>
            </FwFormInput>
        </div>
        <form class='h-mt-15'
              @keyup.enter='goNext(code)'>
            <div class='fw-select-base fw-select-white-modern b-classic-width-input'>
                <FwFormInput
                    labelType='label-medium'
                    :title='$t("SELECT.POSTCODE.APPOINTMENT.PLACEHOLDER")'>
                    <FwInput
                        v-focus='true'
                        class='qa-select-appointment-post-code'
                        :value='code'
                        type='number'
                        name='text'
                        inputStyle='white-bigger'
                        :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                        :placeholder='`ex: ${postCodePlaceholder}`'
                        @update:value='value => code = value'
                        @blur='addCurrentInputToValidateArray("code")'>
                    </FwInput>
                </FwFormInput>
            </div>
        </form>
    </div>

    <portal to='appointment-bar-buttons'>
        <FwButton
            class='qa-appointment-set-post-code'
            size='little'
            :disabled='isFormDisabled'
            borderRadiusType='small-border'
            @click.native='goNext(code)'>
            {{ $t('BUTTON.NEXT') }}
        </FwButton>
    </portal>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Validator } from 'simple-vue-validator';
import { namespace } from 'vuex-class';

import { AppointmentCategoryType } from '@/types/Appointment';
import { ClientType } from '@/types/User';
import { Country } from '@/types/Account';

import ValidationMixin from '@/mixins/validation';
import DateMixin from '@/mixins/dateMixin';
import NoDataMixin from '@/mixins/no-data-mixin';
import AppointmentMixin from '@/mixins/appointment';
import PermissionsMixin from '@/mixins/permissions';

import { parseAddress } from '@/helpers/address';

const BreadCrumbsStore = namespace('BreadCrumbsStore');
const CalendarStore = namespace('CalendarStore');
const AppointmentStore = namespace('AppointmentStore');

@Component({
    validators: {
        code(value: string) {
            return Validator.value(value).required().integer();
        },
    },
})
export default class AppointmentPostcodePage extends Mixins(PermissionsMixin, ValidationMixin, DateMixin, NoDataMixin, AppointmentMixin) {
    @AppointmentStore.Mutation('setAppointmentCategory') setAppointmentCategory!: (categoryName: AppointmentCategoryType) => void;
    @CalendarStore.Mutation('setPostCode') setPostCode!: (post_code: string) => void;
    @CalendarStore.Mutation('setCountryIso') setCountryIso!: (country_iso: string) => void;
    @BreadCrumbsStore.Mutation('setFlowKey') setFlowKey!: (key: string) => void;
    @AppointmentStore.State('appointmentCategory') appointmentCategory!: AppointmentCategoryType;
    @AppointmentStore.State('client') client!: ClientType | null;
    @CalendarStore.State('post_code') post_code!: string;

    loading: boolean = false;
    code: string = '';
    country: Country | null = null;

    serverErrors: serverErrors = {
        code: null,
    };

    get isFormDisabled() {
        if (this.geoScopeInternational && this.isByVideoPhoneMethodPage) {
            return !this.isFormValid || this.isByVideoPhoneMethodPage && !this.country;
        }

        return !this.isFormValid;
    }

    get postCodePlaceholder() {
        return this.isGeoScopeInternationalWithCountries && this.country && this.country.country_iso === 'CHE' ? '1200' : '75001';
    }

    get isByVideoPhoneMethodPage() {
        if (!this.appointmentType) {
            return false;
        }

        return this.isOnPhoneFlow || this.isOnVideoFlow;
    }

    async goNext(code?: string) {
        const localCode = code || this.code;
        if (this.geoScopeInternational && this.country) {
            this.setCountryIso(this.country.country_iso);
        }
        if (!localCode) return;
        this.setPostCode(localCode);
        if (this.isOnVideoFlow) {
            this.$router.push({ path: `/appointment/calendar/by-online-video` });
        } else if (this.isOnSiteInBranchFlow) {
            this.$router.push({ path: `/appointment/choose-branch` });
        } else {
            this.$router.push({ path: '/appointment/calendar/on-phone' });
        }
    }

    created() {
        if (this.post_code) {
            this.code = this.post_code;
        }

        if (this.client && this.client.address) {
            const address = parseAddress(this.client.address);

            if (!(this.isOnPhoneFlow || this.isOnVideoFlow || this.isOnSiteFlow || this.isOnSiteInBranchFlow)) {
                this.goNext(address.zip_code);
            } else {
                this.code = '';
            }
        }

        if (this.geoScopeNational) {
            this.goNext(this.geoCountryIsoCode as string);
        }

        if (!this.appointmentCategory) {
            this.$_NoDataMixin_bookFlowNoData();
        }
    }

    @Watch('country')
    updateValue() {
        this.code = '';
    }
}
</script>

<style lang='sass'>
.b-society-selection
    .multiselect__option--highlight
        background: transparent!important

    .multiselect__option--highlight span
        color: rgb(47, 89, 151)!important

    .fw-select-base.fw-select-white-modern, .fw-select-white
        box-shadow: none !important

.b-society-selection
    & > .h-mt-15
        @include media('<=phone')
            width: 100%
</style>
