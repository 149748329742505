import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import {
    BOOK_CALENDAR_ON_SITE,
    BOOK_CALENDAR_ON_PHONE,
    BOOK_CALENDAR_BY_ONLINE_VIDEO,

    BOOK_CALENDAR_ON_SITE_IN_BRANCH,
    BOOK_CALENDAR_IN_BRANCH_FACE_TO_FACE,
    BOOK_CALENDAR_IN_BRANCH_VIDEOCONFERENCE,
    BOOK_CALENDAR_IN_BRANCH_PRIVATE_VIDEO,
    BOOK_CALENDAR_IN_BRANCH_BY_PHONE_PUBLIC,
    BOOK_CALENDAR_IN_BRANCH_BY_PHONE_PRIVATE,
    BOOK_CALENDAR_IN_BRANCH_BY_VIDEO_PRIVATE,
    BOOK_CALENDAR_IN_BRANCH_BY_VIDEO_PUBLIC,
    BOOK_CALENDAR_IN_SERVICE_POINT,

    BOOK_CALENDAR_TYPES,
    CONSULT_CALENDAR_TYPES,
    CONSULT_CALENDAR_COMPANY,
    CONSULT_CALENDAR_USER,
    CONSULT_CALENDAR_BRANCH,
    CONSULT_CALENDAR_HUB,
    CONSULT_CALENDAR_ROOM,
    CONSULT_CALENDAR_SERVICE_POINT,
} from '@/helpers/calendar';
import { SHIFTS_CALENDAR_PARAM } from '@/helpers/consts';

@Component
export default class CalendarMixin extends Vue {
    get isBookCalendarType(): boolean {
        // eslint-disable-next-line max-len
        return Boolean(this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) && BOOK_CALENDAR_TYPES.includes(this.$route.meta.getName(this.$route)));
    }

    get isConsultCalendarType(): boolean {
        // eslint-disable-next-line max-len
        return Boolean(this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) && CONSULT_CALENDAR_TYPES.includes(this.$route.meta.getName(this.$route)));
    }

    get cellSize(): number {
        return this.isBookCalendarType ? 90 : 100;
    }

    get isBookCalendarOnPhone(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === BOOK_CALENDAR_ON_PHONE;
    }

    get isBookCalendarOnSite(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === BOOK_CALENDAR_ON_SITE;
    }

    get isBookCalendarOnSiteInBranch(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === BOOK_CALENDAR_ON_SITE_IN_BRANCH;
    }

    get isBookCalendarByOnlineVideo(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === BOOK_CALENDAR_BY_ONLINE_VIDEO;
    }

    get isBookCalendarInBranchFaceToFace(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === BOOK_CALENDAR_IN_BRANCH_FACE_TO_FACE;
    }

    get isBookCalendarBranchPrivateVideo(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === BOOK_CALENDAR_IN_BRANCH_PRIVATE_VIDEO;
    }

    get isBookCalendarInBranchVideoconference(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === BOOK_CALENDAR_IN_BRANCH_VIDEOCONFERENCE;
    }

    get isBookCalendarBranchByPhonePublic(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === BOOK_CALENDAR_IN_BRANCH_BY_PHONE_PUBLIC;
    }

    get isBookCalendarBranchByPhonePrivate(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === BOOK_CALENDAR_IN_BRANCH_BY_PHONE_PRIVATE;
    }

    get isBookCalendarBranchByVideoPublic(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === BOOK_CALENDAR_IN_BRANCH_BY_VIDEO_PUBLIC;
    }

    get isBookCalendarBranchByVideoPrivate(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === BOOK_CALENDAR_IN_BRANCH_BY_VIDEO_PRIVATE;
    }

    get isBookCalendarInServicePoints(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === BOOK_CALENDAR_IN_SERVICE_POINT;
    }

    get isConsultCalendarCompany(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === CONSULT_CALENDAR_COMPANY;
    }

    get isConsultCalendarBranch(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === CONSULT_CALENDAR_BRANCH;
    }

    get isConsultCalendarUser(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === CONSULT_CALENDAR_USER;
    }

    get isConsultCalendarHub(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === CONSULT_CALENDAR_HUB;
    }

    get isConsultCalendarRoom(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === CONSULT_CALENDAR_ROOM;
    }

    get isConsultCalendarServicePoint(): boolean {
        return this.$route.meta && this.$route.meta.getName && this.$route.meta.getName(this.$route) === CONSULT_CALENDAR_SERVICE_POINT;
    }

    get isConsultCalendarServicePointShifts(): boolean {
        return this.isConsultCalendarServicePoint && this.$route.params.param === SHIFTS_CALENDAR_PARAM;
    }

    get isCalendarWithDriveManagement(): boolean {
        return this.isBookCalendarOnSite || this.isBookCalendarOnSiteInBranch;
    }
};

