import { render, staticRenderFns } from "./AppointmentPostcodePage.vue?vue&type=template&id=3472d4fd"
import script from "./AppointmentPostcodePage.vue?vue&type=script&lang=ts"
export * from "./AppointmentPostcodePage.vue?vue&type=script&lang=ts"
import style0 from "./AppointmentPostcodePage.vue?vue&type=style&index=0&id=3472d4fd&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports